<template>
  <div class="admin-password-reset">
    <a-layout
      :style="{
        minHeight: '100vh',
        minWidth: '100%',
        background: '#fff',
        borderRadius: '6px',
        boxShadow: '0 2px 8px rgba(0, 0, 0, .1)',
      }"
    >
      <a-row type="flex" justify="center">
        <a-col :md="24">
          <a-card
            :style="{
              margin: '0 auto',
              marginTop: '40px !important',
              borderRadius: '15px',
            }"
            class="admin-password-reset-card"
            width="100%"
            :bodyStyle="{ padding: '0px' }"
          >
            <ValidationObserver ref="observer">
              <a-form
                :model="form"
                :label-width="80"
                slot-scope="{ validate, submitting }"
              >
                <!-- Login title & subtitle -->
                <a-form-item>
                  <a-row>
                    <a-col :span="24">
                      <h1 class="admin-password-reset-title">
                        {{ labels.title }}
                      </h1>
                    </a-col>
                  </a-row>
                </a-form-item>

                <Input
                  v-model="form.email"
                  rules="required|email"
                  vid="email"
                  :label="labels.email.label"
                  :placeholder="labels.email.placeholder"
                  size="large"
                  icon="user"
                  iconStyle="color:rgba(0,0,0,.25)"
                  @keyup.enter.native="validate().then(handleSubmit)"
                />

                <!-- Remember me && forgot password -->
                <div class="admin-password-reset-login">
                  <a-button
                    type="link"
                    class="admin-password-reset-login-link"
                    size="small"
                    @click="$router.push({ name: 'admin' })"
                  >
                    <a-icon type="lock" />{{ labels.loginHere }}
                  </a-button>
                </div>

                <a-form-item>
                  <a-button
                    @click="validate().then(handleSubmit)"
                    type="primary"
                    class="admin-password-reset-button"
                    size="large"
                    block
                    :loading="submitting"
                    :disabled="submitting"
                    >{{ labels.sendResetCode }}</a-button
                  >
                </a-form-item>
              </a-form>
            </ValidationObserver>
          </a-card>
        </a-col>
      </a-row>
    </a-layout>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import Input from "../../components/core/VeeValidateForm/Input";
import labels from "@/utils/labels";
import bg from "@/assets/images/admin/login-bg.jpg";
import { mapActions, mapState } from "vuex";
import { adminPageTitle } from "../../utils/utils";
export default {
  name: "PasswordReset",
  components: {
    ValidationObserver,
    Input,
  },
  metaInfo: {
    title: adminPageTitle("Recuperar contraseña"),
  },
  data() {
    return {
      labels: {
        ...labels.passwordReset,
        appName: labels.appName,
        unknownServerError: labels.unknownServerError,
      },
      bg,
      form: {
        email: "",
      },
    };
  },
  methods: {
    ...mapActions("adminAuth", ["handleResetPassword"]),
    async handleSubmit() {
      try {
        await this.handleResetPassword(this.form);
        this.$router.push({ name: "admin" });
        this.$store.state.redirectRoute = null;
        this.$message.success(`${this.labels.success}`);
      } catch (error) {
        if (error.isAxiosError && error.response.status === 404) {
          this.$error({
            title: error?.response?.message || this.labels.error,
            content: error?.response?.data?.message || this.labels.errorMessage,
            centered: true,
          });
        } else {
          this.$notification["error"]({
            message: `${error?.response?.message || this.labels.error} ${
              error?.response?.status || 500
            }`,
            description: error?.response?.data?.message || error.message,
          });
        }
      }
    },
  },
  computed: {
    ...mapState(["redirectRoute"]),
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/variable";
.admin-password-reset {
  font-family: "Montserrat", sans-serif;
  &-button {
    background-color: color(primary) !important;
    box-shadow: 0 8px 15px 0 color(primary-shadow);
    border-radius: 999px;
  }
  &-login {
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    &-link {
      color: color(muted);
      padding: 0;
    }
    @media screen and (max-width: $screen-mobile) {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
  }
  .ant-layout {
    background: url(../../assets/images/admin/login-bg.jpg) no-repeat !important;
    background-color: color(primary) !important;
    min-height: 100vh;
    min-width: 100%;
    border-radius: 6px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    @media screen and (max-width: $screen-mobile) {
      background-size: cover !important;
    }
  }
  &-card {
    width: 500px;
    padding: 20px 30px;
    background-color: color(--white);
    position: relative;
    z-index: 9;
    // box shadow left
    box-shadow: -25px 25px 0px 0px color(primary);
    @media screen and (max-width: $screen-mobile) {
      width: 96%;
      padding: 20px;
      margin: 0 auto;
      box-shadow: none;
    }
  }
  &-title {
    font-size: 20px;
    font-weight: 500;
    color: color(title);
    margin-bottom: 0.25rem;
    text-align: center;
  }
}
</style>
