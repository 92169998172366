var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"admin-password-reset"},[_c('a-layout',{style:({
      minHeight: '100vh',
      minWidth: '100%',
      background: '#fff',
      borderRadius: '6px',
      boxShadow: '0 2px 8px rgba(0, 0, 0, .1)',
    })},[_c('a-row',{attrs:{"type":"flex","justify":"center"}},[_c('a-col',{attrs:{"md":24}},[_c('a-card',{staticClass:"admin-password-reset-card",style:({
            margin: '0 auto',
            marginTop: '40px !important',
            borderRadius: '15px',
          }),attrs:{"width":"100%","bodyStyle":{ padding: '0px' }}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ validate, submitting }){return _c('a-form',{attrs:{"model":_vm.form,"label-width":80}},[_c('a-form-item',[_c('a-row',[_c('a-col',{attrs:{"span":24}},[_c('h1',{staticClass:"admin-password-reset-title"},[_vm._v(" "+_vm._s(_vm.labels.title)+" ")])])],1)],1),_c('Input',{attrs:{"rules":"required|email","vid":"email","label":_vm.labels.email.label,"placeholder":_vm.labels.email.placeholder,"size":"large","icon":"user","iconStyle":"color:rgba(0,0,0,.25)"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;validate().then(_vm.handleSubmit)}},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('div',{staticClass:"admin-password-reset-login"},[_c('a-button',{staticClass:"admin-password-reset-login-link",attrs:{"type":"link","size":"small"},on:{"click":function($event){return _vm.$router.push({ name: 'admin' })}}},[_c('a-icon',{attrs:{"type":"lock"}}),_vm._v(_vm._s(_vm.labels.loginHere)+" ")],1)],1),_c('a-form-item',[_c('a-button',{staticClass:"admin-password-reset-button",attrs:{"type":"primary","size":"large","block":"","loading":submitting,"disabled":submitting},on:{"click":function($event){validate().then(_vm.handleSubmit)}}},[_vm._v(_vm._s(_vm.labels.sendResetCode))])],1)],1)}}])})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }